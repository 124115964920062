<template>
    <Modal
        v-bind="tabInfo.meta"
        :visible="visible"
        :title="modalTitle"
        :loading="tabInfo.isLoading || loading"
        small
        class="custom-create-new"
        :class="tabInfo.active"
        @cancel="closeModal"
    >
        <Tabs>
            <template v-for="tab of tabs" :key="tab.key">
                <Tab :isActive="tab.key === tabInfo.active">
                    <component
                        v-model:archiveModal="archiveModal"
                        :is="tab.component"
                        :tab="tab"
                        :jobId="jobId"
                        @changeTab="changeTab"
                    />
                </Tab>
            </template>
        </Tabs>
        <template #buttons>
            <CreateNewFooter
                :buttons="tabInfo.buttons"
                :disabled="tabInfo.disabled"
                @cancel="cancelTab"
                @createRecord="createRecord"
                @createCertificate="createCertificate"
                @saveRecord="saveRecord"
                @goToCreateRecord="goToCreateRecord"
                @createCertificateWithJob="createCertificateWithJob"
                @createJobAndCertificate="createJobAndCertificate"
            />
        </template>
    </Modal>
</template>
<script>
import { computed, defineComponent, onMounted, provide, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { modalItems, tabs, buttons, modalsMeta } from "../config/constants";
import { api_create_job_and_certificate } from "../config/api";
import { showError, useApi } from "@/shared/api";
import { deepCopy, objectEquality } from "@/shared/lib";
import { createNewRecord } from "../lib/methods";

import { Modal, Tabs, Tab, TextHead } from "@/shared/ui";
import { CreateSelection, FormOrCertificate, SelectJob, CreateRecord, SelectParents } from "@/features/CreateNewTabs";
import CreateNewFooter from "./overview/CreateNewFooter.vue";
const CreateNew = defineComponent({
    name: "CreateNew",
    props: {
        visible: {
            type: Boolean,
        },
        data: {
            type: Object,
        },
        startTab: {
            type: String,
            default: "create_new",
        },
        jobId: {
            type: String,
        },
        customCreate: {
            type: Boolean,
        },
        type: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
        },
    },
    components: {
        Modal,
        Tabs,
        Tab,
        TextHead,
        CreateNewFooter,
        CreateSelection,
        FormOrCertificate,
        SelectJob,
        CreateRecord,
        SelectParents,
    },
    setup(props, { emit }) {
        const i18n = useI18n();
        const currentLoading = computed({
            get() {
                return props.loading;
            },
            set(data) {
                emit("update:loading", data);
            },
        });

        const tabInfo = reactive({
            history: [],
            disabled: [],
            error: {},
            template: {},
            isLoading: false,
            data: { create: "", ex_create: "", customer: {}, job: {}, property: {} },
        });
        const archiveModal = reactive({
            visible: false,
            data: {},
        });

        const itemsByTypes = ref({});

        const modalData = computed({
            get() {
                return { data: props.data };
            },
            set(data) {
                emit("update:data", data);
            },
        });

        provide("metadata", { tabInfo, modalData });
        provide("itemsByTypes", itemsByTypes);

        const modalTitle = computed(() => {
            return i18n.t(`create_new.modal_titles.${tabInfo.active}`, {
                record: i18n.t(`create_new.records.${tabInfo.data.create}`),
            });
        });

        const closeModal = () => {
            const active = props.startTab;
            tabInfo.buttons = buttons[active];
            tabInfo.meta = modalsMeta[active];
            tabInfo.active = active;
            tabInfo.error = {};
            tabInfo.template = {};
            emit("update:visible", false);
        };

        const changeTab = (data, ignoreHistory) => {
            if (!ignoreHistory) {
                tabInfo.history.push(tabInfo.active);
            }
            tabInfo.active = data;
            tabInfo.buttons = buttons[data] || [];
            tabInfo.meta = modalsMeta[data] || {};
            tabInfo.error = {};
            tabInfo.template = {};
        };

        const cancelTab = () => {
            const previousTab = tabInfo.history.pop();

            if (tabInfo.ex_create) {
                tabInfo.create = tabInfo.ex_create;
                tabInfo.ex_create = "";
            }

            tabInfo.disabled = [];

            if (previousTab) {
                changeTab(previousTab, true);
            } else {
                closeModal();
            }
        };

        const selectParents = () => {
            changeTab("select_parents");
        };

        const createRecord = () => {
            changeTab("create_record");
        };

        const goToCreateRecord = () => {
            if (tabInfo.data.customer || tabInfo.data.property) {
                let query = "";
                const { customer, property } = tabInfo.data;
                if (property.id) {
                    query += `?prop_id=${property.id}`;
                } else if (customer.id) {
                    query += `?cust_id=${customer.id}`;
                }
                window.location.href = tabInfo.data.href + query;
            }
        };
        const createCertificateWithJob = () => {
            tabInfo.data.create = "job";
            changeTab("create_job_with_certificate");
        };

        const createCertificate = () => {
            if (tabInfo.data?.job?.id && tabInfo.data?.certificate?.href) {
                window.location.href = tabInfo.data.certificate.href(tabInfo.data?.job?.id);
            }
        };

        const createJobAndCertificate = async () => {
            try {
                tabInfo.isLoading = true;
                const { certName } = tabInfo.data.certificate;
                const property_id = tabInfo.data.property.id;
                const res = await useApi.post_form_data({
                    link: api_create_job_and_certificate(certName),
                    data: { property_id, job_type: certName },
                });
                const { job_id, success, redirect } = res || {};
                if (success) {
                    if (job_id) {
                        tabInfo.data.job.id = job_id;
                        createCertificate();
                    } else if (redirect) {
                        window.location.href = redirect;
                    }
                }
            } catch (err) {
                showError(err);
            } finally {
                tabInfo.isLoading = false;
            }
        };

        const saveRecord = async () => {
            if (tabInfo.ex_create) {
                cancelTab();
            } else if (tabInfo.active === "select_job") {
                closeModal();
                window.location.href = `/certificate/${tabInfo.data.certificate}`;
            } else if (tabInfo.data.create) {
                const key = tabInfo.data.create;
                if (props.customCreate) {
                    emit("createRecord", tabInfo.data[key]);
                    tabInfo.isLoading = false;
                    return;
                }
                createNewRecord({ tabInfo, key, isLoading: tabInfo });
            }
        };

        onMounted(() => {});

        watch(
            () => tabInfo.isLoading,
            (data) => {
                currentLoading.value = data;
            }
        );

        watch(
            () => props.errors,
            (newData, oldData) => {
                if (!objectEquality(newData, oldData)) {
                    tabInfo.error = props.errors;
                }
            }
        );
        watch(
            () => tabInfo.error,
            (data) => {
                emit("update:errors", deepCopy(data));
            }
        );

        watch(
            () => props.visible,
            (data) => {
                if (!data) {
                    return;
                }
                const { startTab } = props;
                tabInfo.buttons = buttons[startTab];
                tabInfo.meta = modalsMeta[startTab];
                tabInfo.active = startTab;

                if (startTab === "create_record") {
                    tabInfo.data.create = props.type;
                }

                if (startTab === "select_job") {
                    tabInfo.data.create = props.type;
                }
            },
            { immediate: true }
        );

        return {
            i18n,
            modalData,
            modalItems,
            tabInfo,
            tabs,
            buttons,
            modalTitle,
            archiveModal,
            closeModal,
            changeTab,
            cancelTab,
            selectParents,
            createRecord,
            saveRecord,
            createCertificate,
            goToCreateRecord,
            createCertificateWithJob,
            createJobAndCertificate,
        };
    },
});
export default CreateNew;
</script>

<style lang="scss">
@import "./assets/create-new";
</style>
