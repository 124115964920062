import { useNotificationsStore } from "@/shared/model/store";
import { localization, standartizeLink } from "../lib";

const singleErrorKeys = ["orRequired"];
let notificationStore = null;

/**
 * Creates a notification with the given body and type.
 *
 * @param {string} body - The body of the notification.
 * @param {string} type - The type of the notification. Possible values are "error" or "success".
 * @return {void} This function does not return a value.
 */
const notification = (body, type) => {
    if (notificationStore === null) {
        notificationStore = useNotificationsStore();
    }

    notificationStore.addNotification({
        head: type === "error" ? "Something went wrong!" : "Success",
        body,
        type,
    });
};

const sendToSentry = (requestType, error, link, data) => {
    window?.Sentry?.captureException(new Error(`error from ${requestType} request: ${link} `), {
        extra: { error, data },
    });
};

const standardHeaders = {
    "Content-Type": "application/json; charset=utf-8",
    "X-Requested-With": "XMLHttpRequest",
};

const setRequestOptions = (options, headless, controller, ignoreTimeout) => {
    if (!ignoreTimeout) {
        setTimeout(() => controller.abort(), 20000);
    }

    if (headless) {
        options.headers["Content-Type"] = "application/json; charset=utf-8";
    } else {
        Object.assign(options.headers, standardHeaders);
        options.credentials = "include";
    }

    return options;
};

/**
 * Checks the response for redirect and errors and notifies the user accordingly.
 *
 * @param {Response} response - The response object to check.
 * @param {boolean} redirect - Whether to redirect if the response is redirected.
 * @return {Promise<Object|boolean>} The result object or false if an error occurred.
 */
const checkRedirectAndErrors = async (response, redirect) => {
    try {
        if (response.redirected) {
            if (!response.url.includes(window.location.pathname) && redirect) {
                window.location = standartizeLink(response.url);
            }
        }

        const result = await response.json();

        if ((typeof result?.success == "boolean" && !result?.success) || result?.errors?.length) {
            if (!result.errors) {
                notification(result.message, "error");
            } else {
                for (const key in result.errors) {
                    notification(result.errors[key], "error");
                }
            }
        } else if (result?.error) {
            notification(result.error, "error");
        }

        if (result.redirect && !result.redirect.includes(window.location.pathname) && redirect) {
            window.location = standartizeLink(result.redirect);
        }

        return result;
    } catch (error) {
        throw { error, response };
    }
};

const serealizeError = (message) => {
    if (typeof message !== "object" && !!message) {
        return message;
    }

    let usedKeys = {};
    singleErrorKeys.forEach((key) => {
        usedKeys[key] = false;
    });

    for (let key in message) {
        const value = message[key];
        const isSingleError = singleErrorKeys.includes(value);

        if (isSingleError && !usedKeys[value]) {
            usedKeys[value] = true;
            continue;
        } else if (value && !isSingleError) {
            continue;
        }
        delete message[key];
    }
    return message;
};

/**
 * Displays an error notification based on the provided error object.
 *
 * @param {Error} error - The error object containing the error message.
 * @return {void} This function does not return anything.
 */
export const showError = (error) => {
    if (notificationStore === null) {
        notificationStore = useNotificationsStore();
    }

    const message = serealizeError(JSON.parse(error.message) ?? error.message);

    if (message === "required") {
        notificationStore.addNotification({
            type: "error",
            head: localization(`toasts.error`),
            body: localization(`toasts.errors.fill_required`),
        });
    } else if (message instanceof Object) {
        for (const key in message) {
            if (!message[key]) continue;
            notificationStore.addNotification({
                type: "error",
                head: localization(`errors.${message[key]}_valid`),
                body: localization(`records.fields.${key}`),
            });
        }
    } else {
        notificationStore.addNotification({
            type: "error",
            head: localization(`toasts.error`),
            body: localization(`toasts.errors.standart`),
        });
    }
};

export const useApi = {
    /**
     * Asynchronously makes a GET request to the specified link.
     *
     * @param {Object} options - The options for the GET request.
     * @param {string} options.link - The URL to make the GET request to.
     * @param {Object} [options.headers={}] - Additional headers to include in the request.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {AbortController} [options.controller=new AbortController()] - The AbortController to use for cancelling the request.
     * @param {boolean} [options.notificate=true] - Whether to notify the user if an error occurs.
     * @param {boolean} [options.ignoreTimeout=false] - Whether to ignore the timeout and not cancel the request after 3 seconds.
     * @return {Promise<Object|boolean|Error>} - A promise that resolves to the result of the request, or true if the response is successful, or an error if one occurs.
     */
    get: async ({
        link,
        headers = {},
        redirect = true,
        controller = new AbortController(),
        notificate = true,
        ignoreTimeout = false,
        headless = false,
    }) => {
        const options = setRequestOptions(
            {
                headers,
                method: "GET",
                signal: controller.signal,
            },
            headless,
            controller,
            ignoreTimeout
        );

        try {
            if (link.includes("?")) {
                link = link + "&ajax=true";
            } else {
                link = link + "?ajax=true";
            }

            const response = await fetch(link, options);

            const result = await checkRedirectAndErrors(response, redirect);

            if (result.success === false) {
                throw result;
            }

            return result || true;
        } catch (err) {
            if (notificate) {
                notification(err.response, "error");
            }

            sendToSentry("get", err, link);
            return new Error("get error", { cause: err });
        }
    },
    /**
     * Sends a POST request to the specified link with the provided data and headers.
     *
     * @param {Object} options - The options object.
     * @param {string} options.link - The URL to send the POST request to.
     * @param {Object} options.data - The data to send in the request body.
     * @param {Object} [options.headers={}] - The headers to include in the request.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {boolean} [options.headless=false] - Whether the request is headless or not.
     * @param {boolean} [options.ignoreErrors=false] - Whether to ignore errors or not.
     * @param {AbortController} [options.controller=new AbortController()] - The AbortController to use for cancelling the request.
     * @param {boolean} [options.ignoreTimeout=false] - Whether to ignore the timeout and not cancel the request after 3 seconds.
     * @return {Promise<Object|null>} The result of the request or null if an error occurred.
     */
    post: async ({
        link,
        data,
        headers = {},
        redirect = true,
        headless = false,
        ignoreErrors = false,
        controller = new AbortController(),
        ignoreTimeout = false,
    }) => {
        const options = setRequestOptions(
            {
                method: "POST",
                body: JSON.stringify(data),
                headers,
                signal: controller.signal,
            },
            headless,
            controller,
            ignoreTimeout
        );

        try {
            const response = await fetch(link, options);

            const result = await checkRedirectAndErrors(response, redirect);

            if (result.success === false) {
                throw result;
            }

            return result;
        } catch (err) {
            if (!ignoreErrors) {
                notification(err.response, "error");
            }

            sendToSentry("post", err, link, data);
            return new Error("post error", { cause: err });
        }
    },
    /**
     * Sends a PATCH request to the specified link with the provided data.
     *
     * @param {Object} options - The options object.
     * @param {string} options.link - The URL to send the PATCH request to.
     * @param {Object} options.data - The data to send in the request body.
     * @param {Object} [options.headers={}] - The headers to send in the request.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {boolean} [options.headless=false] - Whether to send the request with the "Content-Type" header set to "application/json".
     * @return {Promise<Object|null>} The result of the request or null if an error occurred.
     */
    patch: async ({
        link,
        data,
        headers = {},
        redirect = true,
        headless = false,
        controller = new AbortController(),
        ignoreTimeout = false,
    }) => {
        const options = setRequestOptions(
            {
                method: "PATCH",
                body: JSON.stringify(data),
                headers,
                signal: controller.signal,
            },
            headless,
            controller,
            ignoreTimeout
        );

        try {
            const response = await fetch(link, options);

            const result = await checkRedirectAndErrors(response, redirect);

            if (result.success === false) {
                throw result;
            }

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("patch", err, link, data);
            return new Error("patch error", { cause: err });
        }
    },
    /**
     * Sends a PUT request to the specified link with the provided data.
     *
     * @param {Object} options - The options object.
     * @param {string} options.link - The URL to send the PUT request to.
     * @param {Object} options.data - The data to send in the request body.
     * @param {Object} [options.headers={}] - The headers to send with the request.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {boolean} [options.headless=false] - Whether to send the request with the "Content-Type" header set to "application/json".
     * @return {Promise<Object|null>} The result of the request or null if an error occurred.
     */
    put: async ({
        link,
        data,
        headers = {},
        redirect = true,
        headless = false,
        controller = new AbortController(),
        ignoreTimeout = false,
    }) => {
        const options = setRequestOptions(
            {
                method: "PUT",
                body: JSON.stringify(data),
                headers,
                signal: controller.signal,
            },
            headless,
            controller,
            ignoreTimeout
        );

        try {
            const response = await fetch(link, options);

            const result = await checkRedirectAndErrors(response, redirect);

            if (result.success === false) {
                throw result;
            }

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("put", err, link, data);
            return new Error("put error", { cause: err });
        }
    },
    /**
     * Sends a DELETE request to the specified link with the provided headers.
     *
     * @param {Object} options - The options object.
     * @param {string} options.link - The URL to send the DELETE request to.
     * @param {Object} [options.headers={}] - The headers to send with the request.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {boolean} [options.headless=false] - Whether to send the request with the "Content-Type" header set to "application/json".
     * @return {Promise<Object|null>} The result of the request or null if an error occurred.
     */
    delete: async ({
        link,
        headers = {},
        redirect = true,
        headless = false,
        controller = new AbortController(),
        ignoreTimeout = false,
    }) => {
        const options = setRequestOptions(
            {
                method: "DELETE",
                headers,
                signal: controller.signal,
            },
            headless,
            controller,
            controller,
            ignoreTimeout
        );
        if (headless) {
            headers = { "Content-Type": "application/json", ...headers };
        } else {
            headers = { ...standardHeaders, ...headers };
        }
        try {
            const response = await fetch(link, options);

            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("delete", err, link);
            return new Error("delete error", { cause: err });
        }
    },
    /**
     * Sends a POST request to the specified link with a file in the request body.
     *
     * @param {Object} options - The options object.
     * @param {string} options.link - The URL to send the POST request to.
     * @param {File} options.data - The file to send in the request body.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @return {Promise<Object|null>} The result of the request or null if an error occurred.
     */
    post_file: async ({ link, data, redirect = true }) => {
        try {
            const newData = new FormData();
            newData.append("file", data);

            const response = await fetch(link, {
                method: "POST",
                credentials: "include",
                body: newData,
            });

            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("post_file", err, link, data);
            return new Error("post_file error", { cause: err });
        }
    },
    /**
     * Asynchronously sends a POST request with form data to the specified link.
     *
     * @param {Object} options - The options for the POST request.
     * @param {string} options.link - The URL to send the POST request to.
     * @param {Object} options.data - The form data to send in the request body.
     * @param {boolean} [options.redirect=true] - Whether to redirect if the response is redirected.
     * @param {AbortController} [options.controller=new AbortController()] - The AbortController to use for cancelling the request.
     * @return {Promise<Object|null>} - A promise that resolves to the result of the request or null if an error occurred.
     */
    post_form_data: async ({ link, data, redirect = true, controller = new AbortController() }) => {
        try {
            let formData = new FormData();
            const entriesData = Object.entries(data);
            if (entriesData.length) {
                entriesData.forEach(([key, value]) => {
                    if (!Array.isArray(value)) {
                        formData.append(key, value ?? "");
                    } else {
                        value.forEach((el) => {
                            formData.append(`${key}[]`, el);
                        });
                    }
                });
            } else {
                formData = data;
            }

            const response = await fetch(link, {
                method: "POST",
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                },
                signal: controller.signal,
                body: formData,
            });

            const result = await checkRedirectAndErrors(response, redirect);

            if (result.success === false) {
                throw result;
            }

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("post_form_data", err, link, data);
            return new Error("post_form_data error", { cause: err });
        }
    },
    /**
     * Asynchronously makes an XMLHttpRequest GET request to the specified link with the provided access token.
     *
     * @param {Object} options - The options for the GET request.
     * @param {string} options.link - The URL to make the GET request to.
     * @param {string} options.access_token - The access token to include in the Authorization header.
     * @return {Promise<Object|string>} - A promise that resolves to the parsed response JSON if the status is in the 2xx range, or an object with the error property set to "404" otherwise.
     */
    get_xhr: async ({ link, access_token }) => {
        return await new Promise((resolve) => {
            const xhr = new XMLHttpRequest();

            xhr.open("GET", link);
            xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) resolve(JSON.parse(this.responseText));
                else resolve({ err: "404" });
            };
            xhr.send();
        });
    },
    post_headless: async ({
        link,
        data,
        headers = {},
        redirect = true,
        ignoreErrors = false,
        controller = new AbortController(),
        ignoreTimeout = false,
    }) => {
        if (!ignoreTimeout) {
            setTimeout(() => controller.abort(), 20000);
        }
        try {
            const response = await fetch(link, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    ...headers,
                },
                signal: controller.signal,
            });
            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            if (!ignoreErrors) {
                notification(err.response, "error");
            }

            sendToSentry("post_headless", err, link, data);
            return null;
        }
    },
    get_headless: async ({ link, headers = {}, redirect = true, ignoreErrors = false }) => {
        try {
            const response = await fetch(link, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    ...headers,
                },
            });
            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            if (!ignoreErrors) {
                notification(err.response, "error");
            }

            sendToSentry("get_headless", err, link);
            return null;
        }
    },
    patch_headless: async ({ link, data, headers, redirect = true }) => {
        try {
            const response = await fetch(link, {
                method: "PATCH",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    ...headers,
                },
            });
            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("patch_headless", err, link, data);
            return null;
        }
    },
    delete_headless: async ({ link, headers, redirect = true }) => {
        try {
            const response = await fetch(link, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    ...headers,
                },
            });

            const result = await checkRedirectAndErrors(response, redirect);

            return result;
        } catch (err) {
            notification(err.response, "error");
            sendToSentry("delete_headless", err, link);
            return null;
        }
    },
};
