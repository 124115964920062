export const applianceIdKeys = {
    oil_appliance: "oilappliance_id",
    gas_appliance: "appliance_id",
};

export const applianceTypesFromTables = {
    oil_appliances: "oil_appliance",
    gas_appliances: "gas_appliance",
};

export const backendApplianceTypes = {
    oil_appliance: "oilappliance",
    gas_appliance: "appliance",
};

export const applianceNamesList = {
    oil_appliances: true,
    gas_appliances: true,
};

export const restoreLinksByType = {
    invoice: (id) => `/invoice/archiveinvoicehandler/${id}?reason=from job&archive=0`,
    other: (id, type) => `/ajax/update_cert/${type}/${id}`,
};
