<template>
    <div class="page-wrapper custom">
        <PageHeader v-if="!headless" :notificate="notificate">
            <template #updates>
                <slot name="updates" />
            </template>
        </PageHeader>
        <Breadcrumbs v-if="!headless" />
        <div class="page" :class="{ _wide: isWide }">
            <div class="custom-container">
                <slot />
            </div>
        </div>
        <PageFooter :version="version" />
        <OnboardingComponent />
        <NotificationsToaster />
        <BottomBanner />
        <AccessModal />
    </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, provide, ref, watchEffect } from "vue";
import { wide_urls } from "@/shared/config";
import { useUserStore } from "@/shared/model";

import PageHeader from "@/widgets/PageHeader";
import Breadcrumbs from "@/widgets/Breadcrumbs";
import PageFooter from "@/widgets/PageFooter";
import OnboardingComponent from "@/widgets/OnboardingComponent";
import BottomBanner from "@/widgets/BottomBanner";
import NotificationsToaster from "@/features/NotificationsToaster";
import AccessModal from "@/widgets/AccessModal";

const PageWrapper = defineComponent({
    name: "PageWrapper",
    props: {
        headless: {
            type: Boolean,
        },
        version: {
            type: String,
        },
        notification: {
            type: Object,
            default() {
                return { links: null, info: null };
            },
        },
    },
    components: {
        PageHeader,
        PageFooter,
        Breadcrumbs,
        OnboardingComponent,
        AccessModal,
        BottomBanner,
        NotificationsToaster,
    },
    setup(props) {
        const isWide = computed(() => wide_urls.includes(window.location.pathname));
        const location = ref(window.location.href);
        const app = getCurrentInstance();
        const store = useUserStore();

        provide("notificationInitData", props.notification);

        watchEffect(() => {
            app.appContext.config.globalProperties.$currency = store.currency;
        });
        provide("currency", store.currency);

        return { isWide, location };
    },
});

export default PageWrapper;
</script>
