<template>
    <div class="gallery-item">
        <img :src="currentData.url" :alt="currentData.title" class="gallery-item__image" @click="openGallery" />
        <div class="gallery-item__info">
            <div class="gallery-item__title">
                <template v-if="!isEdit">
                    <TextHead as="h6" bold :text="currentData.title" />
                    <Icon icon="edit" @click="changeEdit" />
                </template>
                <template v-else>
                    <InputStandard v-model:data="currentData.title" />
                    <Icon icon="circle_check" @click="changeEdit" />
                </template>
            </div>
            <InputRadio :title="i18n.t('gallery.info.do_not_send')" v-model:data="currentData.private" />
        </div>
        <SeparatePage />
        <div class="gallery-item__actions">
            <CustomButton small :text="i18n.t('gallery.button.delete')" translucent icon="trash" @click="deleteItem" />
            <CustomButton
                button-component="a"
                :href="`/job/downloadPhoto/${currentData.photo_id}`"
                download
                small
                :text="i18n.t('gallery.button.download')"
                icon="download"
                v-if="currentData.photo_id && currentData.url"
            />
        </div>
    </div>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import { TextHead, Icon, InputRadio, CustomButton, InputStandard, SeparatePage } from "@/shared/ui";

const GalleryItem = defineComponent({
    name: "GalleryItem",
    props: {
        image: {
            type: Object,
        },
    },
    components: { TextHead, Icon, InputRadio, CustomButton, InputStandard, SeparatePage },
    emit: ["deleteItem"],
    setup(props, { emit }) {
        const i18n = useI18n();

        const isEdit = ref(false);

        const changeEdit = () => {
            isEdit.value = !isEdit.value;
        };

        const currentData = computed({
            get() {
                const data = props.image;
                if (typeof data !== "string") {
                    data.private = !!+data.private;
                }
                return data;
            },
            set(data) {
                data.private = +data.private;
                emit("update:image", data);
            },
        });

        const openGallery = () => {
            emit("openGallery", currentData.value);
        };
        const deleteItem = () => {
            emit("deleteItem", currentData.value);
        };

        return { currentData, i18n, isEdit, changeEdit, openGallery, deleteItem };
    },
});
export default GalleryItem;
</script>
<style lang="scss">
@import "./gallery-item";
</style>
