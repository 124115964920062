import { defineStore } from "pinia";
import { ref } from "vue";

export const useAccessModal = defineStore("accessModal", () => {
    const isAccessModalVisible = ref(false);
    const accessModalType = ref("core");

    const listener = document.addEventListener("openAccessModal", () => {
        openAccessModal("core");
    });

    const openAccessModal = (type) => {
        isAccessModalVisible.value = true;
        accessModalType.value = type;
    };

    const closeAccessModal = () => {
        isAccessModalVisible.value = false;
    };

    window.onbeforeunload = () => {
        document.removeEventListener("openAccessModal", listener);
    };

    return {
        isAccessModalVisible,
        accessModalType,
        openAccessModal,
        closeAccessModal,
    };
});
